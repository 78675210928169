import Script from 'next/script';

export default function StripeBillingScript() {
  return (
    <>
      <Script
        async
        src="https://js.stripe.com/v3/pricing-table.js"
      />
    </>
  );
}
