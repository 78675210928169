import { createSlice } from '@reduxjs/toolkit';

export interface SelectProspectsIdState {
  selectedProspectsId: number | null;
  refetchProspectsTable: boolean;
  refetchContactsTable: boolean;
}

const initialState: SelectProspectsIdState = {
  selectedProspectsId: null,
  refetchProspectsTable: false,
  refetchContactsTable: false
};

const selectProspectsIdSlice = createSlice({
  name: 'selectProspectsId',
  initialState,
  reducers: {
    selectProspects: (state, action) => {
      const { id } = action.payload;

      state.selectedProspectsId = id;
    },
    deselectProspects: (state, action) => {
      const { id } = action.payload;
      if (state.selectedProspectsId === id) {
        state.selectedProspectsId = null;
      } else {
        state.selectedProspectsId = id;
      }
    },
    clearSelectedProspects: (state) => {
      state.selectedProspectsId = null;
    },
    setRefetchProspectsTable: (state, action) => {
      state.refetchProspectsTable = action.payload;
    },
    setRefetchContactsTable: (state, action) => {
      state.refetchContactsTable = action.payload;
    }
  }
});

export const {
  selectProspects,
  clearSelectedProspects,
  deselectProspects,
  setRefetchProspectsTable,
  setRefetchContactsTable
} = selectProspectsIdSlice.actions;

export default selectProspectsIdSlice.reducer;
