import Script from 'next/script';
import React from 'react';

const LinkedInTrackingCode: React.FC = () => {
  return (
    <>
      <Script id="linkedin-tracking-script">
        {`
        _linkedin_partner_id = "4928300";
        window._linkedin_data_partner_ids =
        window._linkedin_data_partner_ids || [];
       window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
            if (!l){window.lintrk = function(a,b)
        {window.lintrk.q.push([a,b])};
        window.lintrk.q=[]} var s =
        document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=4928300&fmt=gif"
        />
      </noscript>
    </>
  );
};

export default LinkedInTrackingCode;
