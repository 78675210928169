import { createSlice } from '@reduxjs/toolkit';

export interface FilterCustomerTimelineItemsState {
  timelineItem: string[];
  currentPage: number;
  refetch: boolean;
}

const initialState: FilterCustomerTimelineItemsState = {
  timelineItem: [],
  currentPage: 1,
  refetch: false
};

const filterTimelineItemsSlice = createSlice({
  name: 'filterTimelineItems',
  initialState,
  reducers: {
    changeFilterCustomerTimelineItems: (state, action) => {
      const { item, changeType } = action.payload;
      switch (changeType) {
        case 'added':
          state.currentPage = 1;
          if (!state.timelineItem.includes(item)) {
            state.timelineItem.push(item);
          }
          break;
        case 'removed':
          state.currentPage = 1;
          state.timelineItem = state.timelineItem.filter(
            (existingItem) => existingItem !== item
          );
          break;
        default:
          break;
      }
    },
    resetFilterCustomerTimelineItems: (state) => {
      state.timelineItem = [];
      state.currentPage = 1;
    },
    setTimelineCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    refetchTimelineItems: (state, action) => {
      state.refetch = action?.payload;
      console.log('refetchTimelineItems', state.refetch);
    }
  }
});

export const {
  changeFilterCustomerTimelineItems,
  resetFilterCustomerTimelineItems,
  setTimelineCurrentPage,
  refetchTimelineItems
} = filterTimelineItemsSlice.actions;

export default filterTimelineItemsSlice.reducer;
