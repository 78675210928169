// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface SettingsTabsState {
  id: 0 | 1 | 2 | 3;
  value: number;
  label: 'location' | 'integrations' | 'notifications' | 'billing';
  locationTabs: string;
}

const initialState: SettingsTabsState = {
  id: 0,
  value: 1,
  label: 'location',
  locationTabs: 'general'
};

const settingsTabSlice = createSlice({
  name: 'settingsTab',
  initialState,
  reducers: {
    changeSettingsTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'location';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'integrations';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.label = 'notifications';
      } else if (action.payload === 4) {
        state.id = 2;
        state.value = 3;
        state.label = 'billing';
      }
      state.value = action.payload;
    },
    changeLocationsTabs: (state, action) => {
      state.locationTabs = action.payload;
    }
  }
});

// export the settingsTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeSettingsTabs, changeLocationsTabs } =
  settingsTabSlice.actions;

// exporting the settingsTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default settingsTabSlice.reducer;
