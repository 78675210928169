import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface ProspectCreateFormData {
  id?: number;
  first_name?: string;
  last_name?: string;
  date_of_birth?: Date | string | undefined;
  email?: string | undefined;
  gender?: string | undefined;
  mobile?: string | undefined;
  address?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  postal_code?: number | string | undefined;
  prospect_lead_source?: string | undefined;
  prospect_lead_strength?: string | undefined;
  prospect_potential_value?: number | undefined;
  prospect_stage?: string | undefined;
  can_receive_text_messages?: boolean | undefined;
  can_receive_emails?: boolean | undefined;
  company?: string | undefined;
  job_title?: string | undefined;
  website?: string | undefined;
  fb_messenger_id?: string | undefined;
  twitter_handle?: string | undefined;
  instagram_handle?: string | undefined;
  linked_in_handle?: string | undefined;
}
interface ProspectFormState {
  prospectFormData: ProspectCreateFormData | null;
}
const initialState: ProspectFormState = {
  prospectFormData: null
};
export const prospectFormSlice = createSlice({
  name: 'prospectForm',
  initialState,
  reducers: {
    setProspectFormData: (
      state,
      action: PayloadAction<ProspectCreateFormData | null>
    ) => {
      state.prospectFormData = action.payload;
    },
    clearProspectFormData: (state) => {
      state.prospectFormData = null;
    }
  }
});
export const { setProspectFormData, clearProspectFormData } =
  prospectFormSlice.actions;
export default prospectFormSlice.reducer;
