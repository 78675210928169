import { UserInterface } from '@/lib/types/user-interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

interface AuthState {
  user: UserInterface | null;
  token: string | null;
  assignments: Assignment[] | null;
}

interface ActiveAssignmentsPayload {
  active_assignments: Assignment[];
}

export interface Assignment {
  action_taken?: string;
  assignable_id?: number;
  assignable_type?: string;
  assigner_id?: number;
  created_at: Dayjs;
  customer_name?: string;
  customer_store: {
    id: number;
    name: string;
  };
  customer_store_id: number;
  employee_id?: number;
  id: number;
  status?: string;
  store_id: number;
  updated_at: Dayjs;
  urgency?: string;
  user_id?: number;
}

const initialState: AuthState = { user: null, token: null, assignments: null };

type SetCredentialsPayload = {
  user: object;
  token: string;
  assignments: Assignment[] | null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state: AuthState,
      action: PayloadAction<SetCredentialsPayload>
    ) => {
      const { user, token, assignments } = action.payload;
      state.user = user;
      state.token = token;
      state.assignments = assignments;
    },
    logOut: (state: AuthState) => {
      state.user = null;
      state.token = null;
      state.assignments = null;
    },
    //update user.active_assignments
    updateActiveAssignments: (
      state: AuthState,
      action: PayloadAction<ActiveAssignmentsPayload>
    ) => {
      const { active_assignments } = action.payload;
      if (state.user) {
        state.user = {
          ...state.user,
          active_assignments: active_assignments
        };
      }
    }
  }
});

export const { setCredentials, logOut, updateActiveAssignments } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: AuthState) => state.user;
export const selectCurrentToken = (state: AuthState) => state.token;
