import { increment } from '@/lib/state/slices/notification-bell-slice';

const logger = (storeAPI: any) => (next: any) => (action: any) => {
  const result = next(action);
  if (action.type === increment.type) {
    // check if the action is increment
    console.log('Counter:', storeAPI.getState().counter.value);
  }
  return result;
};

export default logger;
