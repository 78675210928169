import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Customer } from '../../types/communication-center';

interface FormattedCustomer {
  item: Customer;
  score?: number;
}
export interface ImageAttachedType {
  attributes: {
    uuid: string;
    name: string;
    order: number;
    custom_properties: object;
    preview_url: string;
    extension: string;
    size: number;
  };
  client_preview: string;
  upload: {
    errorMessage: null;
    hadErrorWhileUploading: boolean;
    hasFinishedUploading: boolean;

    uploadProgress: number;
  };
  clientValidationErrors: any[];
}

export interface ActiveCustomer {
  customer: Customer;
}

export interface CustomerAgent {
  customer: Customer;
  customer_list: Customer[];
}

export interface CommunicationState {
  customers: Customer[];
  activeCustomerId: number | null;
  activeCustomer: ActiveCustomer | null;
  startDateFilter: string | null;
  endDateFilter: string | null;
  formattedCustomersForSearch: FormattedCustomer[];
  filteredCustomers: FormattedCustomer[] | Customer[];
  activeChannel: string | null;
  imageAttached: ImageAttachedType | null;
}

const initialState: CommunicationState = {
  customers: [],
  activeCustomerId: null,
  activeCustomer: null,
  startDateFilter: null,
  endDateFilter: null,
  formattedCustomersForSearch: [],
  filteredCustomers: [],
  activeChannel: 'note',
  imageAttached: null
};

const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    getCustomers(
      state: CommunicationState,
      action: PayloadAction<Customer[]>
    ): void {
      const customers = action.payload;

      state.customers = customers;
    },
    updateStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload;
      const startDateFilteredCutomers = state.customers.filter(
        (customer: Customer) => {
          return dayjs(customer.recent_activity_at).isAfter(
            dayjs(state.startDateFilter)
          );
        }
      );

      state.customers = startDateFilteredCutomers;
      state.formattedCustomersForSearch = startDateFilteredCutomers.map(
        (customer: Customer) => {
          return {
            item: customer
          };
        }
      );
      state.filteredCustomers = startDateFilteredCutomers.map(
        (customer: Customer) => {
          return {
            item: customer
          };
        }
      );
    },
    setImageAttached: (
      state,
      action: PayloadAction<ImageAttachedType | null>
    ) => {
      state.imageAttached = action.payload;
    },
    resetImageAttached: (state) => {
      state.imageAttached = null;
    },

    updateEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload;
      const endDateFilteredCustomers = state.customers.filter(
        (customer: Customer) => {
          return dayjs(customer.recent_activity_at).isBefore(
            dayjs(state.endDateFilter)
          );
        }
      );
      state.customers = endDateFilteredCustomers;
      state.formattedCustomersForSearch = endDateFilteredCustomers.map(
        (customer: Customer) => {
          return {
            item: customer
          };
        }
      );
      state.filteredCustomers = endDateFilteredCustomers.map(
        (customer: Customer) => {
          return {
            item: customer
          };
        }
      );
    },

    updateActiveCustomerId: (state, action) => {
      state.activeCustomerId = action.payload;
      state.imageAttached = null;
    },
    setActiveCustomer: (state, action) => {
      state.activeCustomer = action.payload;
    },
    setActiveChannel: (state, action) => {
      state.activeChannel = action.payload;
    }
  }
});

export const {
  getCustomers,
  updateStartDateFilter,
  updateEndDateFilter,
  updateActiveCustomerId,
  setActiveCustomer,
  setActiveChannel,
  setImageAttached,
  resetImageAttached
} = communicationSlice.actions;

export default communicationSlice.reducer;
